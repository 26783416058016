.AppLayout {
  display: flex;
  flex-direction: column;

  &__header {
    color: $color-white;
  }

  &__row {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 64px;
    overflow: hidden;
    @media screen and (min-width: 1281px) {
      padding-left: 272px;
    }
  }

  &__menu--wrapper {
    z-index: 9999;
    position: fixed;
    top: 64px;
  }

  &__menu--appName {
    background-color: inherit;
    font-family: $font-family-default;
  }

  &__top-bar {
    background: $color-still900;
    position: sticky;
    z-index: 9999;
    top: 0
  }

  &__user {
    &--dropdown {
      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:nth-child(n) {
          font-weight: 400;
          font-size: $font-size-xs;
          line-height: 14px;
        }
        &-username {
          color: $color-still200;
        }
      }
    }
  }

}
