.TableauLayout {
    padding-top:48px; // Remove onece Space component is added
  
    &__title-icon {
        cursor: pointer;
    }

    .Heading {
        color: $color-white
    }
}