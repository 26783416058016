$breakpoints: (
  'medium': 770px,
  'large': 1200px,
);

// Spacings
// @TODO: Define some spacings
$gutters: (
  'xs': 8px,
  's': 16px,
  'm': 24px,
  'l': 36px,
  'xl': 40px,
  'ios-notch-safe': env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left),
);

// Colors
$color-white: #fff;
$color-black: #000;
$color-solid-secondary: #8f9397;

// Stills
$color-still100: #f0f3f5;
$color-still200: #dee0e2;
$color-still300: #c6c6c6;
$color-still400: #8f9397;
$color-still500: #5c5c5c;
$color-still600: #333333;
$color-still800: #111111;
$color-still900: #0a0a0a;

// Color map
$colors: (
  'white': $color-white,
  'black': $color-black,
  'solid-secondary': $color-solid-secondary,
);

@each $colorName, $colorValue in $colors {
  .color-#{$colorName} {
    color: $colorValue;
  }
}

// Sizes
$topbar-height: 80.88px;
