.LoginBackground {
  position: fixed;
  top: #{$topbar-height};
  left: -45px;
  right: 0;
  height: calc(100% - #{$topbar-height});
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: url('./../../../assets/svg/login-bg.svg') no-repeat center;
  background-clip: border-box;
  background-size: cover;
  flex-direction: column;
  pointer-events: none;

  animation: filter-animation 10s infinite;
  @keyframes filter-animation {
    0% {
      filter: hue-rotate(0turn);
    }

    50% {
      filter: hue-rotate(0.5turn);
    }

    100% {
      filter: hue-rotate(1turn);
    }
  }
}

.LoginContainer {
  width: 100%;
  height: calc(100% - #{$topbar-height});
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-clip: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;

  &__box {
    color: $color-white;
    width: 400px;
    box-sizing: border-box;
    text-align: center;
  }

  &__heading {
    font-family: wigrumweb;
    font-size: 48px;
    font-weight: normal;
  }

  &__app-name {
    font-family: ocrey;
    font-weight: 500;
    color: $color-solid-secondary;
  }

  &__login-button {
    display: flex;
    justify-content: center;
    gap: 32px;
    flex-wrap: wrap;

    .Button {
      width: 224px;
      margin-bottom: 21px;
    }
  }
}
