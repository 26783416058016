@import 'src/definitions';
@import 'src/mixins';
@import 'src/utils';
@import 'src/typography';

* {
  box-sizing: border-box;
}

@import '../node_modules/plume-ui/dist/style.css';

body {
  background-color: black;
  color: #c6c6c6;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
}

// Components

// Containers
.AppContainer {
  height: 100vh;
}
@import 'src/layouts/AppLayout';
@import 'src/layouts/TableauLayout';

@import 'src/features/login/containers/LoginContainer';
@import 'src/features/settings/containers/SettingsContainer';
